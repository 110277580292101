import axios from '@/plugins/axios'
import { getAccountId } from '@/helper/auth-helper'

const getAllTrafficSources = () => axios.get(`${getAccountId()}/traffic-source/all`)
const getTrafficSources = params => axios.get(`${getAccountId()}/traffic-sources/paginated`, { params })
const getAllTrafficSourcesEvents = () => axios.get(`${getAccountId()}/traffic-sources/events/all`)
const getSystemParams = () => axios.get(`${getAccountId()}/url-params/system`)
const getAllTrafficSourcesTemplates = () => axios.get(`${getAccountId()}/traffic-sources/templates/all`)
const getTrafficSourcesTemplates = params => axios.get(`${getAccountId()}/traffic-sources/templates/paginated`, { params })
const getDerailTrafficSource = trafficSourceId => axios.get(`${getAccountId()}/traffic-sources/${trafficSourceId}`)
const createTrafficSource = formData => axios.post(`${getAccountId()}/traffic-sources`, formData)
const updateTrafficSource = ({ trafficSourceId, formData }) => axios.patch(`${getAccountId()}/traffic-sources/${trafficSourceId}`, formData)
const attachUrlParamsToTrafficSource = ({ trafficSourceId, formData }) => axios.patch(`${getAccountId()}/traffic-sources/${trafficSourceId}/url-params`, formData)
const updateAttachedUrlParams = ({ trafficSourceId, urlParamId, formData }) => axios.patch(`${getAccountId()}/traffic-sources/${trafficSourceId}/url-params/${urlParamId}`, formData)
const detachUrlParamsFromTrafficSource = ({ trafficSourceId, params }) => axios.delete(`${getAccountId()}/traffic-sources/${trafficSourceId}/url-params`, { params })
const deleteTrafficSource = trafficSourceId => axios.delete(`${getAccountId()}/traffic-sources/${trafficSourceId}`)

const attachTrafficSourceToNumber = formData => axios.post(`${getAccountId()}/traffic-sources-action/attach-to-number`, formData)
const detachTrafficSourceToNumber = params => axios.delete(`${getAccountId()}/traffic-sources-action/attach-to-number`, { params })

export {
  getAllTrafficSources, getTrafficSources, getAllTrafficSourcesEvents, getSystemParams, getAllTrafficSourcesTemplates, getTrafficSourcesTemplates, getDerailTrafficSource, createTrafficSource, updateTrafficSource, attachUrlParamsToTrafficSource, updateAttachedUrlParams, detachUrlParamsFromTrafficSource, deleteTrafficSource, attachTrafficSourceToNumber, detachTrafficSourceToNumber
}